<template>
    <div class="container">
        <div class="buy-coins">
            <div class="coins-left">
                <div class="title"><span class="logo">{{sysconfig.name}}</span><img class="img" src="@/assets/images/eu5/Glasses.png" /><span class="descript">{{$t('home.home_guide')}}</span></div>
                <div class="title1"><img class="img1" src="@/assets/images/eu5/greenBar.png" /><span>{{$t('home.home_guide')}}</span><img class="img2" src="@/assets/images/eu5/Star.png" /></div>
                <ul>
                    <li>{{$t('home.home_threshold')}}</li>
                    <li>{{$t('home.home_wind_control')}}</li>
                    <li>{{$t('home.home_possibilities')}}</li>
                    <li>{{$t('home.home_anywhere')}}</li>
                </ul>
            </div>
            <div class="coins-img">
                 <img  src="@/assets\images\eu5\magnifier@2x.png" alt="" />
            </div>
        </div>
        <div class="manage-money">
            <div class="title"> {{ $t('home.footer_services') }}</div>
            <div class="content">
                <div class="money-left">
                    <div class="text">
                        <div class="bottom-title">{{$t('home.home_assetsProtected')}}</div>
                        <div class="content">{{$t('home.home_comprehensive')}}</div>
                        <div class="jumps">
                            <router-link to="/quotes" class="d-flex align-items-center">
                                {{ $t('home.label_more')+" >" }}
                            </router-link>
                        </div>
                    </div>  
                    <img  class="img" src="@/assets\images\eu5\Butterfly.png" alt="" />              
                </div>
                <div class="money-right">
                    <div class="text">
                        <div class="bottom-title">{{$t('home.home_anywhere')}}</div>
                        <div class="content">{{$t('home.home_quicklysafer')}}</div>
                        <div class="jumps">
                            <router-link to="/quotes" class="d-flex align-items-center">
                                {{ $t('home.label_more')+" >" }}
                            </router-link>
                        </div>
                    </div>    
                    <img  class="img1" src="@/assets\images\eu5\tadpole.png" alt="" />            
                </div>
            </div>
        </div>
        <div class="palm-quotes">
           <div class="left">
                <div class="one">{{ $t('home.market_trend') }}</div>
                <div class="two">{{ $t('home.features_safety') }}</div>
                <div class="three">{{ $t('home.features_leading_ex') }}</div>
                <router-link to="/futures" class="four">{{ $t('home.home_transactions') }}</router-link>
           </div>
           <div class="right">
                <img  class="img" src="@/assets\images\eu5\cellPhone.png" alt="" />    
           </div>
        </div>
        <div class="palm-bottom">
            <h2>{{ $t('home.home_cryptocurrency_journey') }}</h2>
            <div class="content">
                <div class="one">
                    <div ><div class="img"></div></div>
                    <div class="title">{{ $t('home.home_choose') }}</div>
                    <div class="Sub-label">{{ $t('home.home_quicklysafer') }}</div>
                </div>
                <div class="two">
                    <div ><div class="img"></div></div>
                    <div class="title">{{ $t('home.home_choose') }}</div>
                    <div class="Sub-label">{{ $t('home.home_quicklysafer') }}</div>
                </div>
                <div class="three">
                    <div><div class="img"></div></div>
                    <div class="title">{{ $t('home.home_choose') }}</div>
                    <div class="Sub-label">{{ $t('home.home_quicklysafer') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Deposit from '../../Finance/Deposit.vue'
export default {
  components: { Deposit },
    data() {
        return {
           
        }
    },
    created() { },
    methods: {

    }
}
</script>
<style lang="less" scoped>
ul,li{
    margin: 0;
    padding: 0;
}
.container{
    width: 100%;
    margin-top: 50px;
    .buy-coins{
        background: #0202F8;
        border-radius: 0px 0px 0px 0px;
        width: 100%;
        min-height: 450px;
        display: flex;
        padding: 0 10px;
        .coins-left,.coins-img{
            width: 50%;
        }
        .coins-left{
            padding-top: 10%;
            padding-left: 10%;
            padding-right: 20px;
            padding-bottom: 20px;
            .title{
                margin-top: 12px;
                font-weight: 500;
                color: #fff;
                font-size: 26px;
                .img{
                    width: 100px;
                    height: 50px;
                }
            }
            .title1{
                font-weight: 500;
                color: #fff;
                font-size: 26px;
                .img1{
                    width: 130px;
                    height: 37px;  
                    margin-right: 10px;
                }
                .img2{
                    width: 50px;
                    height: 50px; 
                }
            }
            ul{
                margin-left: 15px;
                color: #fff;
                font-size: 12px;
                li{
                    margin: 5px 0;
                }
            }
        }
        .coins-img{
            text-align: center;
            padding: 10px;
            img{
                width: 390px;
                height: 400px;
            }
        }
    }
    .manage-money{
        margin-top: 40px;
        .title{
            width: 100%;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 26px;
            text-align: center;
            margin-bottom: 30px;
        }
        .content{
            width: 100%;
            display: flex;
        }
        .content>div{
            width: 45%;
            min-height:400px;
        }
        .money-left{
            margin-left: 4%;
            margin-right: 2%;
            background: #63C583FF;
        }
        .money-right{
            background: #5309D1FF;
        }
        .money-left, .money-right{
            position: relative;
            color: #fff;
            padding-left: 20px;
            .bottom-title{
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 14px;
                margin-top: 76px;
                width: 50%;
            }
            .content{
                margin-bottom: 20px;
                width: 50%;
            }
            .jumps a{
                color: #fff;
                width: 100px;
                height: 36px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
            }
            .jumps a:hover{
                color: #fff;
            }
            .img{
                position: absolute;
                bottom: 20px;
                right: 20px;
                width: 240px;
                height: 313px
            }
            .img1{
                position: absolute;
                bottom: 20px;
                right: 20px;
                width: 249px;
                height: 194px;
            }
        }
    }
    .palm-quotes{
        display: flex;
        width: 100%;
        min-height: 280px;
        margin-top: 40px;
        .left{
            width: 45%;
            padding: 10% 20px 20px 12%;
            .one{
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 24px;
                color: #000000;
                margin-bottom: 10px;
            }
            .two{
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
                margin-bottom: 10px;
            }
            .three{
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                margin-bottom: 10px;
            }
            .four{
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #0202F8FF;
            }
        }
        .right{
            width: 55%;
            text-align: center;
            .img{
                width: 450px;
                height: 450px;
            }
        }
    }
    .palm-bottom{
        width: 100%;
        min-height: 280px;
        margin-top: 40px;
        margin-bottom: 10px;
        h2{
            font-weight: 600;
            text-align: center;
            margin-bottom: 20px;
        }
        .content{
            display: flex;
            width: 100%;
            .one,.two,.three{
                width: 32%;
                margin: auto;
                height: 500px;
                background: #FFFFFF;
                box-shadow: 0px 4px 59px 0px rgba(0,0,0,0.07);
                border-radius: 60px 60px 60px 60px;
                padding: 20px 10px 20px 10px;
                >div:nth-child(1){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                }
            }
            .one{
                .title{
                    text-align: center;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
                .img{
                    width: 100%;
                    height: 200px;
                    margin-top: 20px;
                    background: url('@/assets/images/eu5/bankCard.png') no-repeat center;
                    background-size: 100%;
                }
            }
            .two{
                .title{
                    text-align: center;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
                .img{
                    width: 100%;
                    height: 200px;
                    margin-top: 20px;
                    background: url('@/assets/images/eu5/yellowCard.png') no-repeat center;
                    background-size: 100%;
                }
            }
            .three{
                .title{
                    text-align: center;
                    margin-bottom: 20px;
                    margin-top: 10px;
                }
                .img{
                    width: 100%;
                    height: 200px;
                    margin-top: 20px;
                    background: url('@/assets/images/eu5/blueCard.png') no-repeat center;
                    background-size: 100%;
                }
            }
        }
    }
}


 



@media (max-width: 992px) {
}
@media (max-width: 1024px)
{   
    .container{
        .buy-coins{
            background: #0202F8;
            flex-flow: column;
            .coins-left,.coins-img{
                width: 100%;
            }
            .coins-left{
                margin-bottom: 30px;
                padding: 10px 10%;
                .title,.title1{
                    font-size: 22px;
                }
                .title1{
                   .img1{
                        width: 110px;
                        height: 30px;
                   }  
                }
            }
            .coins-img{
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 360px;
                    height: 380px;
                }
            }
        }
        .manage-money{
            .content{
                flex-flow: column;
                .money-left,.money-right{
                    width: 100%;
                    padding-left: 0px;
                    border-radius: 7px;
                    .content,.jumps{
                        width: 100%;
                    }
                }
                .money-left{
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 10px;
                    margin-bottom: 10px;
                    .content,.jumps{
                        width: 50%;
                    }
                    .img{
                        width: 188px;
                        height: 263px;
                    }
                }
                .money-right{
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 10px;
                    margin-bottom: 10px;
                    .content,.jumps{
                        width: 50%;
                    }
                    .img1{
                        width: 223px;
                        height: 200px;
                    }
                }
            }
        }
        .palm-quotes{
            display: flex;
            flex-direction:column-reverse;
            .left,.right{
                width: 100%;
            }
            .right{
                .img{
                    width: 350px;
                    height: 350px;
                }
            }
        }
        .palm-bottom{
             .content{
                display: flex;
                flex-direction: column;
                width: 100%;
                .one,.two,.three{
                    width: 100%;
                    margin: auto;
                    height: 500px;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 59px 0px rgba(0,0,0,0.07);
                    border-radius: 60px 60px 60px 60px;
                    padding: 20px 10px 20px 10px;
                }
                .one{
                    margin-bottom: 10px;
                    .title{
                        text-align: center;
                        margin-bottom: 20px;
                        margin-top: 10px;
                    }
                    .img{
                        width: 330px;
                        height: 200px;
                        margin-top: 20px;
                        background: url('@/assets/images/eu5/bankCard.png') no-repeat center;
                        background-size: 100%;
                    }
                }
                .two{
                    margin-bottom: 10px;
                    .title{
                        text-align: center;
                        margin-bottom: 20px;
                        margin-top: 10px;
                    }
                    .img{
                        width: 330px;
                        height: 200px;
                        margin-top: 20px;
                        background: url('@/assets/images/eu5/yellowCard.png') no-repeat center;
                        background-size: 100%;
                    }
                }
                .three{
                    margin-bottom: 10px;
                    .title{
                        text-align: center;
                        margin-bottom: 20px;
                        margin-top: 10px;
                    }
                    .img{
                        width: 330px;
                        height: 200px;
                        margin-top: 20px;
                        background: url('@/assets/images/eu5/blueCard.png') no-repeat center;
                        background-size: 100%;
                    }
                }
           }
        }
    }
   
}

 





</style>
